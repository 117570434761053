<template>
  <div>
    <v-card height="500px" class="bg-terang-burem-1" elevation="0">
      <v-row align="center" justify="center" style="height: 500px;">
        <v-col class="text-center" cols="12">
          <h1 class="text-h4 mb-4 font-weight-bold" style="color: #424242;">
            Rumah Bersama
          </h1>
          <p class="text--secondary">
            Kerja minimal, untung maksimal.
          </p>
        </v-col>
      </v-row>
    </v-card>
    <v-card height="500px" class="bg-terang-burem" elevation="0">
      <v-row align="center" justify="center" style="height: 500px;">
        <v-col class="text-center" cols="12">
          <h1 class="text-h4 mb-4 font-weight-bold" style="color: #424242;">
            Punya Kosan
          </h1>
          <p class="text--secondary">
            Serasa ga punya beban
          </p>
        </v-col>
      </v-row>
    </v-card>
    <v-card height="500px" class="bg-terang-burem-1" elevation="0">
      <v-row align="center" justify="center" style="height: 500px;">
        <v-col class="text-center" cols="12">
          <h1 class="text-h4 mb-4 font-weight-bold" style="color: #424242;">
            Ribet Ngingetin Bayar?
          </h1>
          <p class="text--secondary">
            Kita yang ingetin
          </p>
        </v-col>
      </v-row>
    </v-card>
    <v-card height="500px" class="bg-terang-burem" elevation="0">
      <v-row align="center" justify="center" style="height: 500px;">
        <v-col class="text-center" cols="12">
          <h1 class="text-h4 mb-4 font-weight-bold" style="color: #424242;">
            Bingung Ngitung Untung?
          </h1>
          <p class="text--secondary">
            Kita yang itungin
          </p>
        </v-col>
      </v-row>
    </v-card>
    <v-card height="500px" class="bg-terang-burem-1" elevation="0">
      <v-row align="center" justify="center" style="height: 500px;">
        <v-col class="text-center" cols="12">
          <h1 class="text-h4 mb-4 font-weight-bold" style="color: #424242;">
            76 Kosan
          </h1>
          <p class="text--secondary">
            Udah pake
          </p>
        </v-col>
      </v-row>
    </v-card>
    <v-card height="500px" class="bg-terang-burem" elevation="0">
      <v-row align="center" justify="center" style="height: 500px;">
        <v-col class="text-center" cols="12">
          <h1 class="text-h4 mb-4 font-weight-bold" style="color: #424242;">
            Kosanmu kapan?
          </h1>
          <p class="text--secondary">
            Kosan sebelah udah
          </p>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
  }),
}
</script>

<style scoped>
@font-face {
  font-family: 'DM Sans';
  src: url('https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitCBimCw.woff2');
}

.bg-terang-burem {
  background-color: #f4f8fa;
}

.bg-terang-burem-1 {
  background-color: #e9f3f8;
}
</style>
