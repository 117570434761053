<template>
  <div>
    <v-card v-if="!balance">
      <v-card-title>
        <v-icon color="primary" class="mr-2">
          mdi-home-city
        </v-icon>
        Selama Datang</v-card-title>

      <v-card-text>
        <template>
          <v-card>
            <v-tabs v-model="tab" background-color="primary" dark fixed-tabs flat>
              <v-tab v-for="item in items" :key="item.tab">
                {{ item.tab }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in items" :key="item.tab">
                <v-card flat>
                  <template v-if="item.content == 'gabung'">
                    <v-card-text> Kamu belum bergabung ke kosan atau kontrakan manapun, silahkan gabung terlebih dahulu
                    </v-card-text>
                    <v-card-text> Kamu bisa minta Nomer Rumah ke Bapak/Ibu kosan atau kontrakan </v-card-text>

                    <v-card-text>
                      <v-alert dense border="left" type="warning" v-if="message">
                        {{ message }}
                      </v-alert>
                      <template v-if="houseData != null">
                        <v-card-text>
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>{{ houseData.id }}</v-list-item-title>
                              <v-list-item-subtitle>Nomor Rumah</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>{{ houseData.name }}</v-list-item-title>
                              <v-list-item-subtitle>Rumah</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>{{ getHouseOwner().name }}</v-list-item-title>
                              <v-list-item-subtitle>Pemilik</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card-text>
                      </template>
                      <v-form ref="form" @submit.prevent="getHouse">
                        <v-text-field v-model="house" filled label="Nomor Rumah" required></v-text-field>
                        <v-btn class="mr-4" color="primary" type="submit">
                          <v-icon class="mr-2">mdi-home-search</v-icon>
                          Cari
                        </v-btn>
                        <v-btn class="mr-4" color="success" @click="joinHouse" v-if="houseData != null">
                          <v-icon class="mr-2">mdi-home-plus</v-icon>
                          Gabung
                        </v-btn>
                      </v-form>
                    </v-card-text>
                  </template>

                  <template v-else>

                    <v-card-title>
                      <v-icon color="primary" class="mr-2">
                        mdi-home-plus
                      </v-icon>
                      Buat Kosan/Kontrakan
                    </v-card-title>
                    <v-card-text> Kamu juga bisa membuat kosan atau kontrakan sendiri loh </v-card-text>
                    <v-card-text> <v-btn class="mr-4" color="primary" @click="redirectOwner()">
                        <v-icon class="mr-2">mdi-home-plus</v-icon>
                        Kosin Owner
                      </v-btn>
                    </v-card-text>
                  </template>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </template>
      </v-card-text>

    </v-card>
    <v-card v-else>
      <v-card-title>
        <v-icon class="mr-2" color="primary">
          mdi-clipboard-clock
        </v-icon> Menunggu Persetujuan </v-card-title>
      <v-card-text> Kamu sudah meminta bergabung kerumah dan menunggu persetujuan pemilik kosan/kontrakan </v-card-text>
      <v-banner @click:icon="alert">
        <v-icon slot="icon" color="warning" size="36">
          mdi-information-outline
        </v-icon>
        Kamu bisa meninggalkan halaman ini. Jika admin sudah menerimamu, kita akan mengabarimu
      </v-banner>
      <v-divider class="mx-4"></v-divider>
      <v-card-title>{{ balance.house.name }}</v-card-title>
      <v-card-subtitle>{{ balance.house.type }}</v-card-subtitle>
      <v-card-text> {{ balance.house.description }} </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { fetchListHouse } from '../../api/house'
import { fetchGetBalance, fetchJoinHouse } from '../../api/balance'
import { fetchGetHouseById } from '../../api/house'


export default {
  data: () => ({
    house: '',
    password: '',
    message: '',
    houseData: null,

    balance: null,

    houses: [],
    tab: null,
    items: [
      { tab: 'Gabung', content: 'gabung' },
      { tab: 'Buat', content: 'buat' },
    ],
  }),
  methods: {
    async listHouse() {
      const res = await fetchListHouse()
      this.houses = res.result.map((value) => ({
        text: `[${value.id}] ${value.name}`,
        value: value.id,
        type: value.type
      }))
    },
    async getBalance() {
      try {
        const res = await fetchGetBalance()
        this.balance = res.result

        if (res.result && res.result.status === 'active') this.$router.push('/')
      } catch (error) {
        this.message = error.data.message ?? 'Data tidak ditemukan'
      }
    },
    async getHouse() {
      this.message = '';
      this.houseData = null
      try {
        const res = await fetchGetHouseById({ houseId: this.house })
        this.houseData = res.result
      } catch (err) {
        this.message = 'Rumah tidak ditemukan, coba lagi';
      }
    },
    async joinHouse() {
      await fetchJoinHouse({ houseId: this.house })
      this.getBalance()
    },
    redirectOwner() {
      window.location.href = process.env.VUE_APP_KOSIN_OWNER_URL
    },
    getHouseOwner() {
      return this.houseData.contracts.filter(value => ['owner', 'admin'].includes(value.role))[0].user
    }
  },
  mounted() {
    this.getBalance()
    this.listHouse()
  }
}
</script>