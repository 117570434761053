<template>
  <div>
    <v-card>
      <v-card-title>Kamu Seorang Admin</v-card-title>
      <v-card-text> Admin dari Kos atau Kontrakan tidak seharusnya disini </v-card-text>

      <v-card-text>
        <v-btn class="mr-4" color="primary" @click="redirectOwner()"> Halaman Admin </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    redirectOwner() {
      window.location.href = process.env.VUE_APP_KOSIN_OWNER_URL
    }
  }
}
</script>