<template>
  <v-card>
    <v-card-text>
      <v-alert dense border="left" type="success" v-if="success">
        Pendaftaran berhasil, silahkan cek email kamu untuk konfirmasi email
      </v-alert>
      <v-alert dense border="left" type="warning" v-if="pesan">
        {{ pesan }}
      </v-alert>
      <v-form ref="form" @submit.prevent="onSend" v-if="!success">
        <v-text-field :value="states.telegram_id" label="Telegram ID" disabled readonly></v-text-field>

        <v-text-field v-model="fullname" label="Nama" required></v-text-field>

        <v-text-field v-model="email" label="Email" required></v-text-field>

        <v-text-field v-model="username" label="Username" required></v-text-field>

        <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
        <v-btn color="primary" type="submit" :disabled="loading">
          <v-icon class="mr-2">mdi-account-plus</v-icon>
          Daftar </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { register } from '../../api/auth'
export default {
  data: () => ({
    email: '',
    username: '',
    password: '',
    fullname: '',

    pesan: '',
    loading: false,
    success: false,
  }),
  methods: {
    ...mapActions('auth', ['sendRegister', 'resetRegister']),
    async onSend() {
      this.setLoading()
      this.setMessage('')
      try {
        const { username, password, fullname, email } = this
        const { telegram_id } = this.states
        await register({ email, username, password, name: fullname, telegram_id })
        this.success = true
      } catch (error) {
        console.log(error.response.data)
        const errorData = error.response.data
        this.setMessage(errorData.errors
          ? (Object.values(error.response.data.errors)[0][0] ?? (errorData.message ?? "Something error, please try again"))
          : "Something error, please try again")
      } finally {
        this.setLoading()
      }
    },
    setLoading() {
      this.loading = !this.loading
    },
    setMessage(message) {
      this.pesan = message
    }
  },
  computed: {
    ...mapState({
      states: (state) => state.auth
    })
  },
  beforeDestroy() {
    this.resetRegister()
  }
}
</script>