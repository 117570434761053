<template>
  <div v-if="contract">
    <v-card color="light-blue darken-4" dark class="rounded-0">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title class="text-h5" v-text="contract.house.name"></v-card-title>

          <v-card-subtitle v-text="contract.house.type"></v-card-subtitle>
          <v-card-text>
            <i>{{ contract.house.description }}</i>
          </v-card-text>

          <v-card-actions>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  <v-icon left>mdi-logout-variant</v-icon>
                  {{ formatDate(contract.end_at) }}
                </v-btn>
              </template>
              <span>Kontrak Selesai</span>
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  <v-icon left>mdi-bell</v-icon>
                  {{ contract.user.latest_tenant ? formatDate(contract.user.latest_tenant.end_at) : 'Tidak Ada' }}
                </v-btn>
              </template>
              <span>Tenan Selesai</span>
            </v-tooltip>
          </v-card-actions>
        </div>

        <v-avatar class="ma-5" color="white" rounded>
          <v-icon x-large color="primary">mdi-home</v-icon>
        </v-avatar>
      </div>

      <v-row dense class="pa-2" v-if="contract.room">
        <v-col cols="12">
          <CardRoom :room="contract.room" />
        </v-col>
      </v-row>
    </v-card>
    <v-container>
      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-account </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ contract.user.name }}</v-list-item-title>
            <v-list-item-subtitle>Nama</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-email </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ contract.user.email }}</v-list-item-title>
            <v-list-item-subtitle>Email</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>

        <v-list-item to="/change-telegram">
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-bell-ring </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ contract.user.telegram_id }}</v-list-item-title>
            <v-list-item-subtitle>Ganti Telegram ID</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item to="/change-password">
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-lock </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Password</v-list-item-title>
            <v-list-item-subtitle>Ganti password</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon color="indigo"> mdi-logout </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
            <v-list-item-subtitle>Keluar dari device ini</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formatNumberRupiah, formatDate } from '../helpers/helper'
import CardRoom from '../components/CardRoom.vue'

export default {
  components: {
    CardRoom
  },
  computed: {
    ...mapState({
      contract: (state) => state.contract.contract
    }),
    isKosan() {
      return this.contract.house.type == 'kosan'
    }
  },
  methods: {
    formatNumberRupiah,
    formatDate,
    logout() {
      localStorage.clear()
      window.location.href = '/auth/login'
    }
  },
  beforeDestroy() {
    this.resetMutation()
  }
}
</script>