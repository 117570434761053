<template>
  <v-card>
    <v-card-title>Ganti Password</v-card-title>
    <v-card-text>
      <v-alert dense border="left" type="info" v-if="message">
        {{ message }}
      </v-alert>
      <v-form ref="form" @submit.prevent="onSend">
        <v-text-field v-model="password" label="Password Baru" type="password" required></v-text-field>

        <v-btn color="primary" type="submit"> Ganti </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  data: () => ({
    password: ''
  }),
  methods: {
    ...mapActions('user', ['changePassword']),
    ...mapMutations('user', ['setMessage']),
    onSend() {
      this.changePassword(this.password)
    }
  },
  computed: {
    ...mapState({
      message: (state) => state.user.message
    })
  },
  beforeDestroy() {
    this.setMessage('')
  }
}
</script>