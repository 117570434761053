<template>
  <v-card>
    <v-card-text>
      Buka bot telegram <a href="https://t.me/RumahBersamaTestBot" target="_blank">@RumahBersamaTestBot</a> untuk
      mendapatkan ID Telegram kamu
    </v-card-text>
    <v-card-text>
      <v-alert dense border="left" type="warning" v-if="states.message">
        {{ states.message }}
      </v-alert>
      <v-form ref="form" @submit.prevent="onSend" class="mb-4">
        <v-text-field v-model="telegram_id" label="Telegram ID" type="number" required></v-text-field>

        <v-btn color="primary" type="submit" :disabled="!states.allowSend">
          <v-icon class="mr-2">mdi-send</v-icon>
          Kirim Kode <span v-if="states.timerCount > 0">({{ states.timerCount }}s)</span>
        </v-btn>
      </v-form>
      <v-form ref="form" @submit.prevent="onVerify" v-if="states.codeSended">
        <v-text-field v-model="code" label="Kode Verifikasi" type="number" required></v-text-field>

        <v-btn color="primary" type="submit"> 
          <v-icon class="mr-2">mdi-shield-check</v-icon>
          Verifikasi Kode </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: () => ({
    telegram_id: '',
    code: '',
    tutorial: [
      'Buka bot telegram <a href="https://t.me/RumahBersamaTestBot" target="_blank">@RumahBersamaTestBot</a>',
    ]
  }),
  methods: {
    ...mapActions('auth', ['sendCodeTelegram', 'verifyCode', 'resetTelegram']),
    onSend() {
      this.sendCodeTelegram({ telegram_id: this.telegram_id })
    },
    onVerify() {
      this.verifyCode({ code: this.code })
    }
  },
  computed: {
    ...mapState({
      states: (state) => state.auth
    })
  },
  beforeDestroy() {
    this.resetTelegram()
  }
}
</script>