import { httpRequest } from "./httpRequest";
import axios from "axios";

export const fetchListHouse = async () => {
  try {
    const res = await httpRequest.get("/tenant/houses");
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response;
    }
    throw error;
  }
};

export const fetchGetHouseById = async ({ houseId }) => {
  try {
    const res = await httpRequest.get(`/tenant/houses/${houseId}`);
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response;
    }
    throw error;
  }
};
