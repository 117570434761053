<template>
  <v-card>
    <v-card-title>
      <v-icon class="mr-2" color="primary">mdi-email-fast</v-icon>
      Kirim Ulang Verifikasi
    </v-card-title>
    <v-card-text>
      <v-alert dense border="left" type="success" v-if="success">
        Link verifikasi sudah kami kirim, silahkan cek email
      </v-alert>
      <v-alert dense border="left" type="warning" v-if="message">
        {{ message }}
      </v-alert>
      <v-form ref="form" @submit.prevent="onSend" v-if="!success">
        <v-text-field v-model="email" label="Email" required></v-text-field>

        <v-btn color="primary" type="submit" :disabled="loading">
          <v-icon class="mr-2">mdi-send</v-icon>
          Kirim
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { sendResendVerification } from '../../api/auth'

export default {
  data: () => ({
    email: '',
    message: '',
    loading: false,

    success: false
  }),
  methods: {
    onSend() {
      this.message = ''
      this.loading = true
      const { email } = this
      sendResendVerification({ email })
        .then(() => (this.success = true))
        .catch((err) => (this.message = err.response.data ? err.response.data.message : 'Failed, please try again'))
        .finally(() => this.loading = false)
    }
  }
}
</script>