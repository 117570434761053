<template>
  <v-card>
    <v-card-title>
      <v-icon class="mr-2" color="primary">
        mdi-login
      </v-icon> Log in
    </v-card-title>
    <v-card-text>
      <v-alert dense border="left" type="warning" v-if="states.message">
        <div class="d-flex justify-space-between align-center">
          <span>{{ states.message }}</span>
          <template v-if="states.message == 'Email not verified'">
            <v-btn dark to="/auth/resend-verification">Kirim Ulang</v-btn>
          </template>
        </div>
      </v-alert>
      <v-form ref="form" @submit.prevent="onSend">
        <v-text-field v-model="email" label="Email" required></v-text-field>

        <v-text-field v-model="password" label="Password" type="password" required></v-text-field>

        <v-btn class="mr-4" color="primary" type="submit">
          <v-icon class="mr-2">mdi-login-variant</v-icon>
          Log in </v-btn>
        <v-btn to="/auth/forgot-password">
          <v-icon class="mr-2">mdi-lock-reset</v-icon>
          Lupa Password </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: () => ({
    email: '',
    password: '',
  }),
  methods: {
    ...mapActions('auth', ['sendLogin']),
    onSend() {
      const { email, password } = this
      this.sendLogin({ email, password })
    }
  },
  computed: {
    ...mapState({
      states: (state) => state.auth
    })
  }
}
</script>