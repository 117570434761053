<template>
  <v-card>
    <v-card-title>Ganti Telegram Akun</v-card-title>
    <v-card-text>
      <v-alert dense border="left" type="info" v-if="message">
        {{ message }}
      </v-alert>

      <v-form ref="form" @submit.prevent="onSend" class="mb-4">
        <v-text-field v-model="telegram_id" label="Telegram ID" type="number" required></v-text-field>

        <v-btn color="primary" type="submit" :disabled="!allowSend">
          <v-icon class="mr-2">mdi-send</v-icon>
          Kirim Kode <span v-if="timerCount > 0">({{ timerCount }}s)</span>
        </v-btn>
      </v-form>
      <v-form ref="form" @submit.prevent="onVerify" v-if="codeSended">
        <v-text-field v-model="code" label="Kode Verifikasi" type="number" required></v-text-field>

        <v-btn color="primary" type="submit">
          <v-icon class="mr-2">mdi-shield-check</v-icon>
          Edit </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import { sendCodeTelegram } from '../api/auth'
import { updateUser } from '../api/user'
export default {
  data: () => ({
    code: '',
    telegram_id: '',
    message: '',

    allowSend: true,
    timerCount: 0,
    codeSended: false
  }),
  methods: {
    ...mapActions('user', ['changePassword']),
    ...mapMutations('user', ['setMessage']),
    async onSend() {
      try {
        await sendCodeTelegram({ telegram_id: this.telegram_id })
        this.timerCount = 10
        this.codeSended = true;
        this.allowSend = false;
        const timerI = setInterval(() => {
          this.timerCount = this.timerCount - 1
          if (this.timerCount < 0) {
            this.allowSend = true
            clearInterval(timerI);
          }
        }, 1000);
      } catch (error) {
        this.message = error.response.data.message ?? 'Gagal mengirim kode, coba lagi';
      }
    },
    onVerify() {
      const codes = [
        Number((this.telegram_id * 3).toString().slice(0, 6)),
        Number((this.telegram_id * 4).toString().slice(0, 6)),
        Number((this.telegram_id * 5).toString().slice(0, 6)),
        Number((this.telegram_id * 6).toString().slice(0, 6)),
        Number((this.telegram_id * 7).toString().slice(0, 6)),
      ];
      if (codes.includes(Number(this.code))) {
        updateUser({ telegram_id: this.telegram_id })
        window.location.href = '/account'

      } else { this.message = 'Kode salah!' }
    }
  },
  computed: {
  },
  beforeDestroy() {
    this.setMessage('')
  }
}
</script>