<template>
  <v-card color="white" dark>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-h5 font-weight-bold hitam-nopekat">
          {{ room.name }}
        </v-card-title>

        <v-card-subtitle class="black--text text--secondary ">
          {{ room.pay_period == 'monthly' ? 'Bulanan' : 'Tahunan' }} | {{ formatNumberRupiah(room.price) }}
        </v-card-subtitle>
      </div>

      <v-avatar class="ma-5" tile>
        <v-icon x-large color="green">mdi-bed</v-icon>
      </v-avatar>
    </div>
  </v-card>
</template>

<script>
import { formatNumberRupiah } from '../helpers/helper'
export default {
  props: {
    room: Object
  },
  methods: {
    formatNumberRupiah
  }
}
</script>

<style scoped>
.hitam-nopekat {
  color: #333 !important;
}
</style>