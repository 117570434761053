<template>
  <v-card>
    <v-card-title>Reset Password</v-card-title>
    <v-card-text>
      <v-alert dense border="left" type="success" v-if="success">
        Password berhasil diubah, silahkan kembali login
      </v-alert>
      <v-alert dense border="left" type="warning" v-if="message">
        {{ message }}
      </v-alert>
      <v-form ref="form" @submit.prevent="onSend" v-if="!success">
        <v-text-field v-model="email" label="Email" readonly disabled required></v-text-field>

        <v-text-field type="password" v-model="password" label="Password Baru" required></v-text-field>
        <v-text-field type="password" v-model="password_confirmation" label="Ulangi Password" required></v-text-field>

        <v-btn color="primary" type="submit"> Change Password </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { sendResetPassword } from '../../api/auth'

export default {
  data: () => ({
    token: '',
    email: '',
    password: '',
    password_confirmation: '',
    message: '',

    success: false
  }),
  methods: {
    onSend() {
      const { token, email, password, password_confirmation } = this
      sendResetPassword({ token, email, password, password_confirmation })
        .then(() => (this.success = true))
        .catch((err) => (this.message = err.response.data ? err.response.data.message : 'Failed, please try again'))
    }
  },
  mounted() {
    this.token = this.$route.query.token
    this.email = atob(this.$route.query.email)
  }
}
</script>