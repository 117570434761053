<template>
  <v-card>
    <v-card-title>
      <v-icon class="mr-2" color="primary">mdi-lock-reset</v-icon>
      Lupa Password
    </v-card-title>
    <v-card-text>
      <v-alert dense border="left" type="success" v-if="success">
        Link untuk reset password sudah dikirim, silahkan cek email
      </v-alert>
      <v-alert dense border="left" type="warning" v-if="message">
        {{ message }}
      </v-alert>
      <v-form ref="form" @submit.prevent="onSend" v-if="!success">
        <v-text-field v-model="email" label="Email" required></v-text-field>

        <v-btn color="primary" type="submit">
          <v-icon class="mr-2">mdi-send</v-icon>
          Kirim
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { sendForgotPassword } from '../../api/auth'

export default {
  data: () => ({
    email: '',
    message: '',

    success: false
  }),
  methods: {
    onSend() {
      this.message = ''
      const { email, password } = this
      sendForgotPassword({ email, password })
        .then(() => (this.success = true))
        .catch((err) => (this.message = err.response.data ? err.response.data.message : 'Failed, please try again'))
    }
  }
}
</script>