<template>
  <v-card>
    <v-card-title>
      <v-icon class="mr-2" color="primary">mdi-account-plus</v-icon>
      Pendaftaran
    </v-card-title>
    <v-stepper v-model="step" vertical elevation="0">
      <v-stepper-step :complete="step > 1" step="1">
        Verifikasi Telegram
      </v-stepper-step>

      <v-stepper-content step="1">
        <Telegram />
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2">
        Mengisi form pendaftaran
      </v-stepper-step>

      <v-stepper-content step="2">
        <Register />
      </v-stepper-content>

    </v-stepper></v-card>
</template>
<script>
import { mapState } from 'vuex';
import Telegram from './Telegram.vue';
import Register from './Register.vue';

export default {
  components: {
    Telegram,
    Register
  },
  data() {
    return {
      e6: 1,
    }
  },
  computed: {
    ...mapState({
      step: (state) => state.auth.step
    })
  },
}
</script>